import React, { useState } from "react"
import {
  Wrapper,
  OffersListWrapper,
  RowWrapper,
  Spacer,
  ButtonWrapper,
} from "./OffresUtilisees.module.css"
import { OrangeTitle } from "./../../../../css/Titre.module.css"
import Button from "./../../../../components/Button"
import OfferCard from "./../../../../components/OfferCard"

const OffresUtilisees = ({ usedOffers }) => {
  const [seeMoreIsVisible, setSeeMoreIsVisible] = useState(
    usedOffers && usedOffers.length > 6 ? true : false
  )
  const [offersCanBeViewed, setOffersCanBeViewed] = useState(6)

  const getFormatedDate = timestamp => {
    return Intl.DateTimeFormat("fr-FR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(timestamp / 1000)
  }

  const handleSeeMoreButton = () => {
    if (offersCanBeViewed + 6 >= usedOffers.length) {
      setSeeMoreIsVisible(false)
    }
    setOffersCanBeViewed(offersCanBeViewed + 6)
  }

  const UsedOffersCards = ({ usedOffers }) => {
    if (usedOffers !== undefined && usedOffers && usedOffers.length > 0) {
      return (
        <div className={RowWrapper}>
          {usedOffers.map((offer, idx = 0) =>
            ++idx <= offersCanBeViewed ? (
              <>
                <OfferCard
                  image={offer.mapValue.fields.image.stringValue}
                  date={getFormatedDate(
                    offer.mapValue.fields.date.integerValue
                  )}
                  category={offer.mapValue.fields.category.stringValue}
                  shopName={offer.mapValue.fields.shopId.stringValue}
                  title={offer.mapValue.fields.title.stringValue}
                />
                <div className={Spacer}></div>
              </>
            ) : null
          )}
        </div>
      )
    } else {
      return <p>Aucune offre encore.</p>
    }
  }

  return (
    <div className={Wrapper}>
      <h1 className={OrangeTitle}>Offres utilisées</h1>
      <div className={OffersListWrapper}>
        <UsedOffersCards usedOffers={usedOffers} />
      </div>
      {seeMoreIsVisible ? (
        <div className={ButtonWrapper}>
          <Button alt="" onClick={() => handleSeeMoreButton()}>
            Voir plus
          </Button>
        </div>
      ) : null}
    </div>
  )
}

export default OffresUtilisees
