import React, { useState, useEffect } from "react";
import { getUrlParams } from "./../../../utils/utils";
import Layout from "./../../../components/Layout";
import OffresUtilisees from "./../../../nav/Admin/Utilisateurs/OffresUtilisees";
import { Helmet } from "react-helmet";
import firebase from "gatsby-plugin-firebase";
import { getUser } from "./../../../auth";
import Error403 from "../../../nav/Error403";
import { TailSpin } from "react-loader-spinner";

const HandleUserStates = ({ user, usedOffers }) => {
  if (user === undefined) {
    return (
      <div style={{ textAlign: "center" }}>
        <TailSpin color="#FF8000" height={200} width={200} />
      </div>
    );
  } else if (user && user.subscription.type === "admin") {
    return <OffresUtilisees usedOffers={usedOffers} />;
  } else {
    return <Error403 />;
  }
};

const OffresUtiliseesPage = () => {
  const [user, setUser] = useState(undefined);
  const [usedOffers, setUsedOffers] = useState(null);

  useEffect(() => {
    getUser(setUser);
    const id = getUrlParams("id");
    firebase
      .firestore()
      .collection("users")
      .doc(id)
      .get()
      .then((doc) => {
        setUsedOffers(
          doc._delegate._document.data.value.mapValue.fields.usedOffers
            .arrayValue.values
        );
      })
      .catch((error) => {
        //console.log("Error getting document:", error)
      });
  }, []);

  return (
    <Layout user={user}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WYNIT | Offres utilisées</title>
        <meta
          name="description"
          content="WYNIT, les bons plans marseillais pour les 15-30 ans. Tu trouveras ici les offres utilisées par les utilisateurs de WYNIT."
        />
        <meta
          name="keywords"
          content="réductions,jeunes,admin,offres utilisées,utilisateur"
        />
        <link
          rel="canonical"
          href="https://wynit.com/admin/utilisateurs/offres-utilisees"
        />
      </Helmet>
      <HandleUserStates user={user} usedOffers={usedOffers} />
    </Layout>
  );
};

export default OffresUtiliseesPage;
