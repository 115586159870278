import React, { useState, useEffect } from "react"
import {
  CardWrapper,
  ImageWrapper,
  ContentWrapper,
  BackgroundWrapper,
  SectionWrapper,
  RowWrapper,
  Spacer,
  WhiteText,
} from "./OfferCard.module.css"
import {
  PaddingRight10,
  PaddingTop10,
  PaddingBottom10,
  PaddingLeft10,
} from "./../../css/Padding.module.css"
import { classNames } from "../../helpers/classNames"
import firebase from "gatsby-plugin-firebase"

const OfferCard = ({ image, date, category, shopName, title }) => {
  const [getShopName, setGetShopName] = useState("")

  useEffect(() => {
    firebase
      .firestore()
      .collection("shops")
      .doc(shopName)
      .get()
      .then(doc => {
        setGetShopName(
          doc._delegate._document.data.value.mapValue.fields.name.stringValue
        )
      })
      .catch(error => {
        //console.log("Error getting document:", error)
      })
  }, [shopName])

  return (
    <div className={CardWrapper}>
      <img alt="offer" src={image} className={ImageWrapper} />
      <div className={ContentWrapper}>
        <div className={BackgroundWrapper}>
          <div className={RowWrapper}>
            <div className={Spacer}></div>
            <p
              className={classNames([PaddingRight10, PaddingTop10, WhiteText])}
            >
              {date}
            </p>
          </div>
          <div className={Spacer}></div>
          <div className={classNames([PaddingBottom10, PaddingLeft10])}>
            <div className={SectionWrapper}>
              <p className={WhiteText}>{category}</p>
            </div>
            <p className={WhiteText}>{getShopName}</p>
            <p className={WhiteText}>{title}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OfferCard

/*<img alt="offer's image" src={image}>
      <div className={ContentWrapper}>
        <div className={RowWrapper}>
          <div className={Spacer}></div>
          <p className={classNames([PaddingRight10, PaddingTop10, WhiteText])}>
            {date}
          </p>
        </div>
        <div className={Spacer}></div>
        <div className={classNames([PaddingBottom10, PaddingLeft10])}>
          <div className={SectionWrapper}>
            <p className={WhiteText}>{category}</p>
          </div>
          <p className={WhiteText}>{getShopName}</p>
          <p className={WhiteText}>{title}</p>
        </div>
      </div>
  </img>*/
